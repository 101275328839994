// © Framer University. All rights reserved.

import type { ComponentType } from "react"
import { motion } from "framer-motion"
import React from "react"

// Learn more: https://www.framer.com/docs/guides/overrides/

/**
 * Based on the code created by Joshua Guo
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */

export function with3D(Component): ComponentType {
    return (props) => {
        const handleMouseMove = (event) => {
            const card = event.currentTarget
            const { offsetWidth: width, offsetHeight: height } = card
            const { clientX, clientY } = event
            const { left, top } = card.getBoundingClientRect()

            const rotateX = (clientY - top - height / 2) / 10
            const rotateY = (clientX - left - width / 2) / 10

            card.style.transform = `perspective(1200px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`

            const image = card.querySelector("img")
            if (image) {
                image.style.transform = `translateZ(50px)`
            }
        }

        const handleMouseLeave = (event) => {
            const card = event.currentTarget
            card.style.transform = `perspective(1200px) rotateX(0deg) rotateY(0deg)`

            const image = card.querySelector("img")
            if (image) {
                image.style.transform = `translateZ(0px)`
            }
        }

        return (
            <motion.div
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                style={{
                    perspective: "1200px",
                    transformStyle: "preserve-3d",
                    width: `${props.width}`,
                    height: `${props.height}`,
                }}
            >
                <Component {...props} />
            </motion.div>
        )
    }
}
